// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Analytics from "../../blocks/analytics/src/Analytics";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Notifications from "../../blocks/notifications/src/Notifications";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Sorting from "../../blocks/sorting/src/Sorting";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import LinkShare from "../../blocks/linkshare/src/LinkShare";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";


const routeMap = {
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;